import {
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useState } from "react";
import { alphanumericSort } from "utils/helpers/extensions";

// TODO: Replace this with original values
const ALBUM_NAMES: string[] = [];

interface Props {
  handleCancelClick: () => void;
}

export const AddToAlbumDialogContent = (props: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  const handleSaveButtonClick = () => {
    /// TODO: Need to integrate API when it is ready
    props.handleCancelClick();
  };

  const handleCheckboxChange = (album: string) => {};

  return (
    <MDBox p="20px">
      <TextField
        fullWidth
        placeholder="Search Albums"
        value={searchInput}
        InputLabelProps={{ shrink: true }}
        onChange={handleSearchInputChange}
        sx={{ py: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
      {/* /// TODO: Replace the dummy with original data once the API is ready */}
      {ALBUM_NAMES.sort((a, b) => alphanumericSort(a, b))
        .filter((album) =>
          album.toLowerCase().includes(searchInput.trim().toLowerCase())
        )
        .map((album, index) => {
          return (
            <FormGroup key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      handleCheckboxChange(album);
                    }}
                  />
                }
                label={album}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontWeight: "400",
                  },
                }}
              />
            </FormGroup>
          );
        })}
      <MDBox px={2} py={2} display="flex" justifyContent="flex-end">
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          size="small"
          onClick={props.handleCancelClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          sx={dialogSaveButtonStyle}
          size="small"
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </MDBox>
    </MDBox>
  );
};
