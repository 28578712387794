/// Used to style the photo section card
export const cardStyle = {
  flexGrow: "1",
  ".MuiCardMedia-root": {
    margin: "0px !important",
    borderRadius: "0px !important",
  },
  ".MuiCardContent-root": {
    backgroundColor: "#000000",
    borderRadius: "0px !important",
    padding: "10px !important",
  },
};

export const escalatedCardStyle = {
  ".MuiCardMedia-root": {
    ...cardStyle[".MuiCardMedia-root"],
  },
  "> .MuiBox-root": {
    position: "relative",
    ":after": {
      position: "absolute",
      content: '""',
      display: "block",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      zIndex: 2,
      boxShadow: "0 0 0 4px #AE1709 inset",
    },
  },
};
export const btnToolTipStyle = {
  ">span": {
    flexGrow: "1",
    display: "flex"
  }
}
export const cardContentStyle = {
  color: "#ffffff !important",
  fontSize: "14px",
  fontWeight: "500px",
};

export const photoSectionCardStyle = {
  "span.MuiTypography-root": {
    fontWeight: "400",
    "+ span.MuiTypography-root": {
      marginTop: "4px",
    },
  },
};

export const photoSectionBadgeStyle = {
  display: "flex",
  alignItems: "center",
  ".MuiBadge-badge": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
    minWidth: "52px",
  },
  ".MuiBadge-root": {
    padding: "0 !important",
    "+ .MuiBadge-root": {
      marginLeft: "10px",
    },
  },
};

export const mapFullViewStyle = {
  width: "100%",
};

/// Used for badges used in top section
export const customBadgeStyle = {
  ".MuiBadge-badge": {
    background: "#344767d9 !important",
    color: "#f8f9fa",
    padding: "12px 8px",
    minWidth: "50px",
  },
};

/// Used for the buttons in the dialog box
export const dialogSaveButtonStyle = {
  ml: 2,
  fontSize: "14px",
  fontWeight: "bold",
  color: "white.main",
  backgroundColor: "success.main",
  "&:hover": { backgroundColor: "success.main" },
};

export const dialogCancelButtonStyle = {
  ml: 2,
  fontSize: "14px",
  fontWeight: "bold",
  color: "#344767",
  borderColor: "dark.main",
  "&:hover": {
    backgroundColor: "white.main",
    color: "dark.main",
    borderColor: "dark.main",
  },
};

export const scrollToTopButtonStyle = {
  borderRadius: "50%",
  minWidth: "unset",
  width: "56px",
  height: "56px",
  padding: "0",
  transition: "all 0.25s ease",
  background: "linear-gradient(to bottom, #63b967, #4ba64f) !important",
  marginBottom: "8px",
};

/// Map section
export const mapToggleButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "24px",
  zIndex: 128,
  color: "#000",
  borderRadius: "3px",
  textShadow: "1px 1px rgb(52, 71, 103)",
  padding: "0",
  background: "rgba(255, 255, 255, 1)",
  ":hover, :focus": {
    background: "rgba(255, 255, 255, 1)",
    color: "#000",
  },
  ":focus, :focus:not(:hover)": {
    boxShadow: "none",
    outline: "0",
    background: "rgba(255, 255, 255, 0.7)",
    color: "#000",
  },
};

/// Style used to blur the image
export const blurImageStyle = {
  img: { filter: "blur(4px)" },
};

/// Style used for metadata dialog content
export const metadataContentStyle = {
  flex: "1 1 auto",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  ".MuiDialogContent-root": {
    px: "24px",
    mb: "20px",
    "p.MuiTypography-root": {
      fontWeight: "400 !important",
    },
  },
};

export const metaDataDialogStyle = {
  flex: "1 1 auto",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  ".MuiDialogContent-root": {
    px: "24px",
    mb: "20px",
    "p.MuiTypography-root": {
      fontWeight: "400 !important",
    },
  },
};

export const escalatedButtonStyle = {
  background: "#EFD1CE !important",
  borderColor: "transparent.main",
  color: "#AE1709 !important",
  fontSize: "14px",
  fontWeight: "600",
  "&:hover, &:focus": { borderColor: "transparent.main" },
};

export const deEscalateButtonStyle = {
  fontSize: "14px",
  height: "40px",
  paddingLeft: "16px",
  paddingRight: "16px",
  borderColor: "#C7CCD0",
};

export const loaderStyle = {
  position: "relative",
  left: "calc(50% - 20px)",
  top: "calc(50% - 20px)",
};

export const playButtonStyle = {
  "&:after": {
    display: "block",
    content: '""',
    position: "absolute",
    zIndex: "3",
    background: "rgba(0, 0, 0, 0.2)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
};

export const pauseButtonStyle = {
  button: {
    display: "none",
  },
  ":hover": {
    button: {
      display: "flex",
    },
  },
};

export const controlButtonStyle = {
  position: "absolute",
  zIndex: "6",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#000000",
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  color: "#ffffff !important",
  ":hover": {
    background: "#000000",
  },
};
