import {
  GridColDef,
  GridRowSelectionModel,
  GridScrollParams,
} from "@mui/x-data-grid-premium";
import { GridData } from "../types/types";
import moment, { Moment } from "moment";
import { VuesPageSummaryState } from "../utils/reducer";
import {
  DashboardVue,
  Vue,
} from "../../../dashboard-home/utils/dashboard_interfaces";
import {
  ImmutableVueTags,
  getVueStatus,
} from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { Company } from "@ivueit/vue-engine";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";

/// Logic for disabling the all buttons when the selected vue list contains read only items
export const containsReadOnlyVuesInList = (
  rows: GridData[],
  selectedRowsList: GridRowSelectionModel,
  availableCompanies: Company[]
): boolean => {
  // Generates an array of read only companies
  const readOnlyCompanies = availableCompanies
    .filter((company) => company.role === UserRoles.immutableReadOnly)
    .map((company) => company.id);
  const hasReadOnlyVues =
    rows
      .filter((row) => selectedRowsList.includes(row.id))
      .filter((vue) => readOnlyCompanies.includes(vue.companyId)).length > 0;
  return hasReadOnlyVues;
};

/// Logic for disabling the "REISSUE" button
export const disableReissueButton = (
  rows: GridData[],
  selectedRowsList: GridRowSelectionModel
): boolean => {
  const shouldDisableReissueButton =
    rows
      .filter((row) => selectedRowsList.includes(row.id))
      .filter((vue) => vue.status.toLowerCase() !== "completed".toLowerCase())
      .length > 0;
  return shouldDisableReissueButton;
};

/// Logic for disabling the "Make Urgent" button
export const disableMakeUrgentButton = (
  rows: GridData[],
  selectedRowsList: GridRowSelectionModel
): boolean => {
  const disableUrgentButton =
    rows
      .filter((row) => selectedRowsList.includes(row.id))
      .filter((vue) => vue.status.toLowerCase() !== "open".toLowerCase())
      .length > 0;
  return disableUrgentButton;
};

/// Logic for disabling the "CANCEL" button
export const disableCancelButton = (
  rows: GridData[],
  selectedRowsList: GridRowSelectionModel
): boolean => {
  const shouldDisableCancelButton =
    rows
      .filter((row) => selectedRowsList.includes(row.id))
      .filter(
        (vue) =>
          vue.status.toLowerCase() !== "Open".toLowerCase() &&
          vue.status.toLowerCase() !== "In Progress".toLowerCase()
      ).length > 0;
  return shouldDisableCancelButton;
};

export const filterDateToNanoSecondsString = (
  date: Moment,
  isEnd = false
): string => {
  if (isEnd) {
    return date.endOf("day").valueOf().milliSecondsToNanoSeconds().toString();
  }
  return date.startOf("day").valueOf().milliSecondsToNanoSeconds().toString();
};

export const convertDateToNanoSecondsString = (date: Moment): string => {
  // Using endOf('day') & startOfDay to ensure that the UTC date will be same
  const dateInMilliSeconds = moment.utc(date).startOf("day").valueOf();
  return dateInMilliSeconds.milliSecondsToNanoSeconds().toString();
};

export const convertDateToNanoSecondsStringForEndDate = (
  date: Moment
): string => {
  // Using endOf('day') & startOfDay to ensure that the UTC date will be same
  const dateInMilliSeconds = moment.utc(date).endOf("day").valueOf();
  return dateInMilliSeconds.milliSecondsToNanoSeconds().toString();
};

export const generateGridAPIParamter = (
  clientVuesState: VuesPageSummaryState,
  pageNumber: number,
  pageSize: number
): string => {
  var parameterString = `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  // Appending types to the parameter string
  if (clientVuesState.types.length > 0) {
    const listOfTypes = clientVuesState.types.join("&types=");
    parameterString += `&types=${listOfTypes}`;
  }

  // Appending submission status to the parameter string
  if (clientVuesState.statuses.length > 0) {
    const listOfStatus = clientVuesState.statuses.join("&statuses=");
    parameterString += `&statuses=${listOfStatus}`;
  }

  // Appending search value
  if (clientVuesState.search.isNotEmpty()) {
    parameterString += `&search=${clientVuesState.search}`;
  }
  // Appending vueID value
  if (clientVuesState.canonicalID.isNotEmpty()) {
    parameterString += `&canonicalID=${clientVuesState.canonicalID}`;
  }
  // Appending vueTitle value
  if (clientVuesState.vueTitle.isNotEmpty()) {
    parameterString += `&vueTitle=${clientVuesState.vueTitle}`;
  }

  // Appending siteNameAndNumber value
  if (clientVuesState.siteNameAndNumber.isNotEmpty()) {
    parameterString += `&siteNameAndNumber=${clientVuesState.siteNameAndNumber}`;
  }

  // Appending completedAtStartDate
  if (clientVuesState.completedAtStartDate.isNotEmpty()) {
    parameterString += `&completedAtStartDate=${clientVuesState.completedAtStartDate}`;
  }

  // Appending completedAtEndDate
  if (clientVuesState.completedAtEndDate.isNotEmpty()) {
    parameterString += `&completedAtEndDate=${clientVuesState.completedAtEndDate}`;
  }

  // Appending completedAtStartTime
  if (clientVuesState.completedAtStartTime.isNotEmpty()) {
    parameterString += `&completedAtStartTime=${clientVuesState.completedAtStartTime}`;
  }

  // Appending completedAtEndTime
  if (clientVuesState.completedAtEndTime.isNotEmpty()) {
    parameterString += `&completedAtEndTime=${clientVuesState.completedAtEndTime}`;
  }

  // Appending createdAtStartDate
  if (clientVuesState.createdAtStartDate.isNotEmpty()) {
    parameterString += `&createdAtStartDate=${clientVuesState.createdAtStartDate}`;
  }

  // Appending createdAtEndDate
  if (clientVuesState.createdAtEndDate.isNotEmpty()) {
    parameterString += `&createdAtEndDate=${clientVuesState.createdAtEndDate}`;
  }

  // Appending createdAtStartTime
  if (clientVuesState.createdAtStartTime.isNotEmpty()) {
    parameterString += `&createdAtStartDate=${clientVuesState.createdAtStartTime}`;
  }

  // Appending createdAtEndTime
  if (clientVuesState.createdAtEndTime.isNotEmpty()) {
    parameterString += `&createdAtEndTime=${clientVuesState.createdAtEndTime}`;
  }

  // Appending slaStartsAtStartDate
  if (clientVuesState.slaStartsAtStartDate.isNotEmpty()) {
    parameterString += `&slaStartsAtStartDate=${clientVuesState.slaStartsAtStartDate}`;
  }

  // Appending slaStartsAtEndDate
  if (clientVuesState.slaStartsAtEndDate.isNotEmpty()) {
    parameterString += `&slaStartsAtEndDate=${clientVuesState.slaStartsAtEndDate}`;
  }

  // Appending slaStartsAtStartTime
  if (clientVuesState.slaStartsAtStartTime.isNotEmpty()) {
    parameterString += `&slaStartsAtStartTime=${clientVuesState.slaStartsAtStartTime}`;
  }

  // Appending slaStartsAtEndTime
  if (clientVuesState.slaStartsAtEndTime.isNotEmpty()) {
    parameterString += `&slaStartsAtEndTime=${clientVuesState.slaStartsAtEndTime}`;
  }

  // Appending postalAddress
  if (clientVuesState.postalAddress.isNotEmpty()) {
    parameterString += `&postalAddress=${clientVuesState.postalAddress.trim()}`;
  }

  // Appending Address 2
  if (clientVuesState.addressTwo.isNotEmpty()) {
    parameterString += `&addressTwo=${clientVuesState.addressTwo.trim()}`;
  }

  // Appending city
  if (clientVuesState.city.isNotEmpty()) {
    parameterString += `&city=${clientVuesState.city.trim()}`;
  }

  // Appending stateProvinceAbbreviation
  if (clientVuesState.stateProvinceAbbreviation.isNotEmpty()) {
    parameterString += `&stateProvinceAbbreviation=${clientVuesState.stateProvinceAbbreviation.trim()}`;
  }

  // Appending zipPostalCode
  if (clientVuesState.zipPostalCode.isNotEmpty()) {
    parameterString += `&zipPostalCode=${clientVuesState.zipPostalCode.trim()}`;
  }

  // Appending country
  if (clientVuesState.countryAbbreviation.length > 0) {
    const countryList =
      clientVuesState.countryAbbreviation.join("&countryShort=");
    parameterString += `&countryShort=${countryList}`;
  }

  // Appending Metadata
  if (Object.keys(clientVuesState.metaKeySearches).length !== 0) {
    const metaKeys = Object.keys(clientVuesState.metaKeySearches);
    metaKeys.forEach((key) => {
      const value = clientVuesState.metaKeySearches[key];
      parameterString += `&metaKeySearches[${key}]=${value}`;
    });
  }

  // Appending escalated
  if (
    clientVuesState.escalated !== null &&
    clientVuesState.escalated !== undefined
  ) {
    parameterString += `&escalated.value=${clientVuesState.escalated}`;
  }
  // Appending vueTags to the parameter string
  const updatedTags = getFilterTagsWithoutImmutableTags(
    clientVuesState.vueTags
  );
  if (updatedTags.length > 0) {
    const vueTagList = updatedTags.join("&vueTags=");
    parameterString += `&vueTags=${vueTagList}`;
  }

  // Appending companyIdsList to the parameter string
  if (clientVuesState.companyIds.length > 0) {
    const companyIdsList = clientVuesState.companyIds.join("&companyIds=");
    parameterString += `&companyIds=${companyIdsList}`;
  }

  // Appending slaTargetMin
  if (clientVuesState.slaTargetMin.isNotEmpty()) {
    parameterString += `&slaTargetMin=${clientVuesState.slaTargetMin.trim()}`;
  }
  // Appending slaTargetMax
  if (clientVuesState.slaTargetMax.isNotEmpty()) {
    parameterString += `&slaTargetMax=${clientVuesState.slaTargetMax.trim()}`;
  }
  // Appending slaActualMin
  if (clientVuesState.slaActualMin.isNotEmpty()) {
    parameterString += `&slaActualMin=${clientVuesState.slaActualMin.trim()}`;
  }
  // Appending slaActualMax
  if (clientVuesState.slaActualMax.isNotEmpty()) {
    parameterString += `&slaActualMax=${clientVuesState.slaActualMax.trim()}`;
  }
  // Appending sortColumnDisplayName
  if (clientVuesState.sortColumnDisplayName.isNotEmpty()) {
    const columnName = clientVuesState.sortColumnDisplayName;
    parameterString += `&sortColumnDisplayName=${
      columnName === "Address 2" ? "Address Two" : columnName
    }`;
  }

  // Appending sortColumnDisplayName
  if (
    clientVuesState.sortIsAscending !== null &&
    clientVuesState.sortIsAscending !== undefined
  ) {
    parameterString += `&sortIsAscending=${clientVuesState.sortIsAscending}`;
  }

  // Appending Urgent
  if (
    clientVuesState.vueTags.findIndex(
      (item) => item === ImmutableVueTags.urgent
    ) !== -1
  ) {
    parameterString += `&urgent.value=${true}`;
  }
  // Appending High quality
  if (
    clientVuesState.vueTags.findIndex(
      (item) => item === ImmutableVueTags.highQuality
    ) !== -1
  ) {
    parameterString += `&highQuality.value=${true}`;
  }
  // Appending Internal
  if (
    clientVuesState.vueTags.findIndex(
      (item) => item === ImmutableVueTags.internal
    ) !== -1
  ) {
    parameterString += `&internal.value=${true}`;
  }
  // Appending BatchID
  if (clientVuesState.batchID.isNotEmpty()) {
    parameterString += `&batchID=${clientVuesState.batchID}`;
  }
  // Appending SurveyTemplateId
  if (clientVuesState.surveyTemplateId.isNotEmpty()) {
    parameterString += `&surveyID=${clientVuesState.surveyTemplateId}`;
  }
  // Appending media tags
  if (clientVuesState.mediaTags.length > 0) {
    const mediaTagList = clientVuesState.mediaTags.join("&mediaTags=");
    parameterString += `&mediaTags=${mediaTagList}`;
  }
  return parameterString.replace("#", "");
};
// Update Tag filter for Immmutable tags
export const getFilterTagsWithoutImmutableTags = (newFilters: string[]) => {
  return newFilters.filter(
    (item) =>
      item !== ImmutableVueTags.urgent &&
      item !== ImmutableVueTags.highQuality &&
      item !== ImmutableVueTags.internal
  );
};
/// This returns the previous value searched by the user in all poppers with text fields
export const getPreviouslySearchedText = (
  clientVuesState: VuesPageSummaryState,
  columnName: string
) => {
  let previouslySearchedText = "";
  const keysList = Object.keys(clientVuesState.metaKeySearches);
  const isMetaColumn = keysList.length !== 0 && keysList.includes(columnName);
  switch (columnName) {
    case "vueId":
      previouslySearchedText = clientVuesState.canonicalID;
      break;
    case "vueName":
      previouslySearchedText = clientVuesState.vueTitle;
      break;
    case "siteName":
      previouslySearchedText = clientVuesState.siteNameAndNumber;
      break;
    case "address":
      previouslySearchedText = clientVuesState.postalAddress;
      break;
    case "address2":
      previouslySearchedText = clientVuesState.addressTwo;
      break;
    case "state":
      previouslySearchedText = clientVuesState.stateProvinceAbbreviation;
      break;
    case "city":
      previouslySearchedText = clientVuesState.city;
      break;
    case "zipCode":
      previouslySearchedText = clientVuesState.zipPostalCode;
      break;
    case columnName:
      previouslySearchedText = isMetaColumn
        ? clientVuesState.metaKeySearches[columnName]
        : "";
      break;
    default:
      break;
  }
  return previouslySearchedText;
};

export const getGridDataFromDashboardVue = (
  dashboardVue: DashboardVue
): GridData => {
  const { vue, companyId } = dashboardVue;
  return {
    id: vue.id,
    vueId: vue.canonicalId,
    vueName: vue.title,
    siteName: getSiteName(vue),
    surveyType: vue.typeOf,
    status: getVueStatus(dashboardVue.status),
    createdDate: vue.createdAt.formatUTCNanoSecondsToString("MM/DD/YYYY"),
    completedDate:
      dashboardVue.vuerCompletedAt.formatUTCNanoSecondsToString("MM/DD/YYYY"),
    address: vue.streetAddress,
    address2: vue.addressTwo,
    state: vue.stateProvinceAbbreviation,
    city: vue.city,
    zipCode: vue.zipPostalCode,
    country: vue.countryAbbreviation,
    hasEscalated: vue.escalated,
    escalationNotes: vue.escalationNotes,
    vueTags: getUpdatedTagsWithBoolean(vue),
    photoTags: vue.mediaTags,
    clientName: dashboardVue.companyName,
    slaTarget: vue.slaTarget,
    slaActual: vue.slaActual,
    latitude: vue.latitude,
    longitude: vue.longitude,
    completedTime:
      dashboardVue.vuerCompletedAt.formatUTCNanoSecondsToString("hh:mm A"),
    isInternal: vue.isInternal,
    urgent: vue.urgent,
    highQualityRequired: vue.highQualityRequired,
    pdfFileId: vue.pdfFileId,
    pdfFileIdOrdered: vue.pdfFileIdOrdered,
    pdfFileIdPhotos: vue.pdfFileIdPhotos,
    pdfFileIdSurvey: vue.pdfFileIdSurvey,
    meta: vue.meta,
    jobSiteId: vue.jobSiteId,
    mapFileId: vue.mapFileId,
    siteNameNumber: vue.siteNameNumber,
    companyId: companyId,
  };
};

/// Append isInternal/urgent/highQualityRequired to Tags if they are true
const getUpdatedTagsWithBoolean = (vue: Vue) => {
  let { isInternal, urgent, highQualityRequired, vueTags } = vue;
  var newTags = vueTags ?? [];
  if (isInternal) newTags.push(ImmutableVueTags.internal);
  if (urgent) newTags.push(ImmutableVueTags.urgent);
  if (highQualityRequired) newTags.push(ImmutableVueTags.highQuality);
  return newTags;
};

const getSiteName = (vue: Vue): string => {
  const siteName = vue.siteNameNumber;
  return siteName.trim().isNotEmpty() ? siteName : "UNASSIGNED";
};

/// "Jump To Top" functionality
export const scrollToTop = (
  gridRef: React.MutableRefObject<GridApiPremium>
) => {
  if (gridRef.current) {
    const scrollParams: GridScrollParams = { left: 0, top: 0 };
    gridRef.current.scroll(scrollParams);
  }
};

export const getCustomColumnDefinitionForField = (title: string) => {
  const definition: GridColDef = {
    field: title,
    headerName: title,
    width: 200,
    minWidth: 180,
    maxWidth: 340,
    sortable: false,
    filterable: true,
    pinnable: false,
    hideable: true,
    valueGetter: (params) => {
      const meta = params.row.meta ?? {};
      const value = meta[title] ?? "";
      return value;
    },
  };

  return definition;
};

// Checks whether the two string arrays are equal or not
export const areArraysEqual = (first: string[], second: string[]): boolean => {
  return (
    first.length === second.length &&
    first.every((value, index) => value === second[index])
  );
};

export const getUniqueArrayElementsWithCase = (array: string[]) => {
  const uniqueArray: string[] = [];
  // Iterate through the array
  array.forEach((element) => {
    // Trim whitespace at both ends
    const trimmedElement = element.trim();

    // Check if a similar element exists in uniqueArray (case-insensitive)
    const existingElement = uniqueArray.find(
      (item) => item.toLowerCase() === trimmedElement.toLowerCase()
    );

    // If not found, add the trimmed element to uniqueArray
    if (!existingElement) {
      uniqueArray.push(trimmedElement);
    }
  });

  return uniqueArray;
};

export const getValueIgnoringCase = (jsonObject: any, keyToFind: string) => {
  for (let key in jsonObject) {
    if (key.toLowerCase() === keyToFind.toLowerCase()) {
      return jsonObject[key];
    }
  }
  return undefined; // Key not found
};
