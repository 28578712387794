import {
  MediaHubAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import { performGetRequest } from "utils/services/NetworkHandler";

export const getClientMediaInfo = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = MediaHubAPIServices.getClientMediaInfo + "?" + parameters;
  return await performGetRequest(url);
};
