import DashboardContentLayout from "layouts/DashboardContentLayout";
import MediaHubGallery from "./MediaHubGallery";
import MDBox from "components/MDBox";
import { useRef } from "react";

export const AlbumDetailScreen = () => {
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <DashboardContentLayout
      containerRef={containerRef}
      needCardBackground={false}
    >
      <MDBox pl="20px" pr="10px">
        <MediaHubGallery containerRef={containerRef} isGalleryScreen={false} />
      </MDBox>
    </DashboardContentLayout>
  );
};
