/// Defines the webservice response status
export enum WebServiceStatus {
  success,
  error,
}

/// Webservice response model that helps to manage every API responses
export interface WebServiceResponse {
  status: WebServiceStatus;
  data?: any;
  error?: string;
}

/// Listed below are the API endpoints
const hostURL = process.env.REACT_APP_DOMAIN;
const apiVersion = process.env.REACT_APP_API_VERSION;
const baseURL = `${hostURL}/api/${apiVersion}`;
const loginModuleURL = `${hostURL}/login/${apiVersion}`;
const profileModuleURL = `${baseURL}`;
const vuesModuleURL = `${baseURL}/vues`;
const dashboardModuleURL = `${vuesModuleURL}/dashboard`;
const clientVuesModuleURL = `${baseURL}/client-vues`;
const vueSurveyModuleURL = `${baseURL}/survey`;
const vuePhotoFileURL = `${baseURL}/photo-file`;
const vueVideoFileURL = `${baseURL}/video-file`;
const generatePresignedFileURL = `${baseURL}/file`;
const vueBatchModuleURL = `${baseURL}/batch`;
const siteListModuleURL = `${baseURL}/sites`;
const siteUpdateModuleURL = `${baseURL}/site`;
const vuersList = `${baseURL}/users/company`;
const vuerRequests = `${baseURL}/internal-network/requests/company`;
const rejectVuer = `${baseURL}/internal-network/reject`;
const approveVuer = `${baseURL}/internal-network/approve`;
const removeVuer = `${baseURL}/internal-network/company/user/remove`;
const singleVuer = `${baseURL}/user`;
const manageUserModuleURL = `${loginModuleURL}/user`;
const manageVueTagsURL = `${baseURL}/vue-tags`;
const manageMediaTagsURL = `${baseURL}/media-tags`;
const mediaHubURL = `${baseURL}/client-media`;

interface LoginServices {
  // Used to login with email and password
  validateEmailAndPassword: string;
  // Used request email for reset password
  sendEmailToResetPassword: string;
  // Used to set password
  setPassword: string;
  // Used to request SMS pincode during setup account
  requestSmsPincode: string;
  // Used to request SMS pincode with email
  requestSMSPinCodeViaEmail: string;
  // Used to request pincode in email
  requestEmailPincode: string;
  // Used to generate auth token for the client portal
  getAuthTokenForClientPortal: string;
  // Used to verify SMS pincode and saves phone number during setup account
  verifySMSPinAndSavePhoneNumber: string;
  // Used to verify the pincode sent in mail
  verifyEmailPincode: string;
  /// Used to check whether the token is valid
  isValidToken: string;
  /// Gets the token refreshed
  refreshToken: string;
}

interface UserProfileServices {
  /// Fetch user details
  getOrgUser: string;
  /// Used to update the user info
  updateOrgUser: string;
  /// Used to update currently logged in user info
  updateMyOrgUser: string;
  /// Update phone number
  updatePhoneNumber: string;
  /// Reverts phone number
  revertPhoneNumber: string;
  /// Uploads file
  uploadFile: string;
  /// Downloads file
  downloadFile: string;
  /// Deletes file
  deleteFile: string;
  /// Updates password
  updatePassword: string;
  /// Returns pre-authenticated phone number
  getVerifiedPhoneNumber: string;
}

interface DashboardServices {
  getVuesForDashboard: string;
  getSummaryFiguresForDashboard: string;
  getVueHistoryByPortalId: string;
}

interface VueGridServices {
  getClientVues: string;
  getAllVueTagNamesToFilter: string;
  getAllMediaTagNamesToFilter: string;
  getMyVuesGridPreferences: string;
  setMyVuesGridColumnWidth: string;
  setMyVuesGridColumnOrder: string;
  setVuesAsUrgent: string;
  reissueVue: string;
  cancelVue: string;
  exportPDF: string;
  downloadVueImage: string;
  editVue: string;
}

interface IndividualVuesServices {
  getIndividualVueByCanonicalId: string;
  getVueTagsByVueId: string;
  replaceVueTagsForVue: string;
  replaceMediaTags: string;
}
interface VueSurveyServices {
  getSurveyTemplateById: string;
  getSurveyTemplateStepState: string;
}
interface VueFileServices {
  getPhotoFileInfo: string;
  downloadPhotoFile: string;
  updatePhotoFile: string;
  generatePresignedFileURL: string;
  uploadEditedPhoto: string;
  deletePhotoEdit: string;
  generatePresignedVideoURL:string;
  handleVideoFileInfo:string;
  regenerateReports:string;
}

interface CreateVueServices {
  getSurveyTemplates: string;
  updateSurveyTemplate: string;
}

interface VueBatchServices {
  getVueBatches: string;
  exportVueBatches: string;
}
interface VuerServices {
  getVuers: string;
  getVuersRequest: string;
  postVuerReject: string;
  postVuerApprove: string;
  vuerSingleUser: string;
  postVuerRemove: string;
}

interface SiteListServices {
  getSiteList: string;
  editSite: string;
  addSite: string;
  bulkImportSiteList: string;
  bulkArchiveSiteList: string;
  exportSiteList: string;
}
interface ManageUserServices {
  getAllUsersOnMyCompany: string;
  getOrgUserByEmail: string;
  getCompanyRolesByEmail: string;
  updateCompanyById: string;
  getCompanyById: string;
  setUserActiveInactive: string;
  createClientOrgUser: string;
  whiteListEmailAndOverwriteCompanyRoles: string;
  sendInviteEmailForNewUser: string;
  removeOrgUser: string;
  removeWhiteListedEmailAndRoles: string;
}
interface SettingsPageServices {
  getVueTags: string;
  addVueTags: string;
  editVueTags: string;
  deleteVueTags: string;
  getMediaTags: string;
  addMediaTags: string;
  editMediaTags: string;
  deleteMediaTags: string;
  generateAPIKeys: string;
}

interface GridPreferences {
  setMyGridColumnWidth: string;
  setMyGridColumnOrderAndVisibility: string;
  getMyGridPreferences: string;
}

interface MediaHubServices {
  getClientMediaInfo: string;
}

interface InvoicesPageServices {
  getInvoicesInfo: string;
}
interface AppVersionServices {
  getAppVersionInfo: string;
  getLoginAppVersionInfo: string;
  getInternalAppVersionInfo: string;
}

export const MediaHubAPIServices: MediaHubServices = {
  getClientMediaInfo: `${mediaHubURL}`,
};

export const LoginAPIServices: LoginServices = {
  validateEmailAndPassword: `${loginModuleURL}/login`,
  sendEmailToResetPassword: `${loginModuleURL}/password/reset`,
  setPassword: `${loginModuleURL}/password/set`,
  requestSmsPincode: `${loginModuleURL}/user/sms/pincode`,
  verifySMSPinAndSavePhoneNumber: `${loginModuleURL}/org-user/sms/verify`,
  requestSMSPinCodeViaEmail: `${loginModuleURL}/user/sms/email-pincode`,
  requestEmailPincode: `${loginModuleURL}/user/email/pincode`,
  getAuthTokenForClientPortal: `${loginModuleURL}/org-user/authtoken`,
  verifyEmailPincode: `${loginModuleURL}/email/verify`,
  isValidToken: `${loginModuleURL}/verify`,
  refreshToken: `${loginModuleURL}/refresh`,
};

export const UserProfileAPIServices: UserProfileServices = {
  getOrgUser: `${profileModuleURL}/org-user/active`,
  updateOrgUser: `${profileModuleURL}/org-user/update`,
  updateMyOrgUser: `${profileModuleURL}/my-org-user/update`,
  updatePhoneNumber: `${profileModuleURL}/org-user/update/phone`,
  revertPhoneNumber: `${profileModuleURL}/org-user/sms/revert`,
  uploadFile: `${profileModuleURL}/file/upload`,
  downloadFile: `${profileModuleURL}/file/download`,
  deleteFile: `${profileModuleURL}/file/delete`,
  updatePassword: `${loginModuleURL}/password/update`,
  getVerifiedPhoneNumber: `${loginModuleURL}/org-user/sms`,
};

export const DashboardAPIServices: DashboardServices = {
  getVuesForDashboard: `${dashboardModuleURL}`,
  getSummaryFiguresForDashboard: `${dashboardModuleURL}/summary`,
  getVueHistoryByPortalId: `${dashboardModuleURL}/history`,
};

export const VueGridAPIServices: VueGridServices = {
  getClientVues: `${clientVuesModuleURL}`,
  getAllVueTagNamesToFilter: `${baseURL}/vue-tags/names`,
  getAllMediaTagNamesToFilter: `${baseURL}/media-tags/names`,
  getMyVuesGridPreferences: `${clientVuesModuleURL}/preferences`,
  setMyVuesGridColumnWidth: `${clientVuesModuleURL}/preferences/width`,
  setMyVuesGridColumnOrder: `${clientVuesModuleURL}/preferences/visibility`,
  setVuesAsUrgent: `${vuesModuleURL}/urgent`,
  reissueVue: `${vuesModuleURL}/reissue`,
  cancelVue: `${baseURL}/vues/client/cancel`,
  exportPDF: `${baseURL}/file/url`,
  downloadVueImage: `${baseURL}/photo-file`,
  editVue: `${baseURL}/vue/edit`
};

export const IndividualVuesAPIServices: IndividualVuesServices = {
  getIndividualVueByCanonicalId: `${clientVuesModuleURL}`,
  getVueTagsByVueId: `${clientVuesModuleURL}/tags`,
  replaceVueTagsForVue: `${clientVuesModuleURL}`,
  replaceMediaTags: `${baseURL}/media-tags/replace`,
};

export const VueSurveyAPIServices: VueSurveyServices = {
  getSurveyTemplateById: `${vueSurveyModuleURL}/template`,
  getSurveyTemplateStepState: `${vueSurveyModuleURL}/step-state`,
};

export const VueFileAPIServices: VueFileServices = {
  getPhotoFileInfo: `${vuePhotoFileURL}`,
  downloadPhotoFile: `${vuePhotoFileURL}`,
  updatePhotoFile: `${vuePhotoFileURL}`,
  generatePresignedFileURL: `${generatePresignedFileURL}/url`,
  uploadEditedPhoto: `${baseURL}/file/edit/upload`,
  deletePhotoEdit: `${baseURL}/file/edit/delete`,
  generatePresignedVideoURL:`${vueVideoFileURL}/url`,
  handleVideoFileInfo:`${vueVideoFileURL}`,
  regenerateReports: `${baseURL}/vue/:id/reports/regenerate`,
};

export const CreateVueAPIServices: CreateVueServices = {
  getSurveyTemplates: `${vueSurveyModuleURL}/templates`,
  updateSurveyTemplate: `${vueSurveyModuleURL}/templates/update`,
};

export const VueBatchAPIServices: VueBatchServices = {
  getVueBatches: `${vueBatchModuleURL}`,
  exportVueBatches: `${baseURL}/export/survey`,
};
export const VuerAPIServices: VuerServices = {
  getVuers: `${vuersList}`,
  getVuersRequest: `${vuerRequests}`,
  postVuerReject: `${rejectVuer}`,
  postVuerApprove: `${approveVuer}`,
  postVuerRemove: `${removeVuer}`,
  vuerSingleUser: `${singleVuer}`,
};
export const SiteListAPIServices: SiteListServices = {
  getSiteList: `${siteListModuleURL}`,
  addSite: `${siteUpdateModuleURL}/add`,
  editSite: `${siteUpdateModuleURL}/edit`,
  bulkImportSiteList: `${siteListModuleURL}/import`,
  bulkArchiveSiteList: `${siteListModuleURL}/archive`,
  exportSiteList: `${baseURL}/export/jobsites`,
};

export const ManageUserAPIServices: ManageUserServices = {
  getAllUsersOnMyCompany: `${loginModuleURL}/users`,
  getCompanyById: `${baseURL}/company`,
  updateCompanyById: `${baseURL}/company/update`,
  getOrgUserByEmail: `${baseURL}/org-user/email`,
  createClientOrgUser: `${baseURL}/org-user/create`,
  removeOrgUser: `${baseURL}/org-user/delete`,

  getCompanyRolesByEmail: `${manageUserModuleURL}/roles`,
  setUserActiveInactive: `${manageUserModuleURL}/set-active`,
  whiteListEmailAndOverwriteCompanyRoles: `${manageUserModuleURL}/create`,
  sendInviteEmailForNewUser: `${manageUserModuleURL}/invite`,
  removeWhiteListedEmailAndRoles: `${manageUserModuleURL}/delete`,
};
export const SettingsPageAPIServices: SettingsPageServices = {
  getVueTags: `${manageVueTagsURL}`,
  addVueTags: `${manageVueTagsURL}/create`,
  editVueTags: `${manageVueTagsURL}/edit`,
  deleteVueTags: `${manageVueTagsURL}/delete`,
  getMediaTags: `${manageMediaTagsURL}`,
  addMediaTags: `${manageMediaTagsURL}/create`,
  editMediaTags: `${manageMediaTagsURL}/edit`,
  deleteMediaTags: `${manageMediaTagsURL}/delete`,
  generateAPIKeys: `${loginModuleURL}/service-credentials`,
};

export const GridPreferenceAPIServices: GridPreferences = {
  setMyGridColumnWidth: `${baseURL}/preferences/width`,
  setMyGridColumnOrderAndVisibility: `${baseURL}/preferences/visibility`,
  getMyGridPreferences: `${baseURL}/preferences`,
};

export const InvoicesAPIServices: InvoicesPageServices = {
  getInvoicesInfo: `${baseURL}/invoices`,
};

export const AppVersionAPIServices: AppVersionServices = {
  getAppVersionInfo: `${baseURL}/service/version`,
  getInternalAppVersionInfo: `${baseURL}/internal-login/${apiVersion}/service/version`,
  getLoginAppVersionInfo: `${loginModuleURL}/service/version`,
};
