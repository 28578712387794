import {
  CardMedia,
  CardContent,
  Grid,
  Card,
  CircularProgress,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {
  blurImageStyle,
  cardContentStyle,
  escalatedCardStyle,
  loaderStyle,
  photoSectionCardStyle,
} from "../../vues/styles/VueDetailStyles";
import { PhotoFileInfo } from "../../vues/vue_detail/utils/vue_detail_interface";

interface Props {
  photoFileInfo: PhotoFileInfo;
}

export const PhotoThumbnail = ({ photoFileInfo }: Props) => {
  return (
    <CardMedia
      component="img"
      height="380px"
      width="100%"
      image={`data:${photoFileInfo.mimeType};base64,${photoFileInfo.data}`}
      alt="Vue"
      sx={{ objectFit: "contain", width: "100% !important" }}
    />
  );
};

export default PhotoThumbnail;
