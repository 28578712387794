import MDBox from "components/MDBox";
import { VideoPlayer } from "../../vues/vue_detail/components/VideoPlayer";
import { PhotoFileInfo } from "../../vues/vue_detail/utils/vue_detail_interface";

interface Props {
  photoFileInfo: PhotoFileInfo;
}

const VideoThumbnail = ({ photoFileInfo }: Props) => {
  return (
    <MDBox>
      <VideoPlayer sourceURL={photoFileInfo.data} shouldPlay={false} />
    </MDBox>
  );
};

export default VideoThumbnail;
