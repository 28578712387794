import {
  CardContent,
  Grid,
  Card,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {
  cardContentStyle,
  loaderStyle,
  photoSectionCardStyle,
} from "../../vues/styles/VueDetailStyles";
import { PhotoFileInfo } from "../../vues/vue_detail/utils/vue_detail_interface";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { tooltipStyles } from "../../vues/vue-grid/components/VueActionBar";
import CustomCheckbox from "pages/dashboard/components/CustomCheckBox";

interface Props {
  photoFileInfo: PhotoFileInfo;
  children: JSX.Element;
  enableSelection: boolean;
  handleMediaClick: (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSelected: boolean;
}

export const MediaContainer = ({
  photoFileInfo,
  children,
  enableSelection,
  handleMediaClick,
  isSelected,
}: Props) => {
  const photoTagsList: string[] = [];

  const getTypography = (label: string) => {
    return (
      <MDTypography sx={{ ...cardContentStyle }} variant="caption">
        {label}
      </MDTypography>
    );
  };

  const selectedStyle = {
    boxShadow: "0 0 0 4px #63b967",
  };
  const escalationStyle = {
    boxShadow: "0 0 0 4px #AE1709",
  };
  const hideStyle = {
    "> .blurContent": {
      filter: "blur(4.2px)",
    },
    overflow: "hidden",
    position: "relative",
    cursor: "default",
    ":before": {
      display: "block",
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.35)",
      zIndex: "6",
    },
    ":after": {
      zIndex: "3",
    },
  };

  // Prepares the card style for the UI
  const getCardStyle = () => {
    var style = {
      cursor: "pointer",
      borderRadius: "1px !important",
      ".MuiCardMedia-root": {
        margin: "0px !important",
        borderRadius: "0px !important",
        border: "1px solid #C7CCD0",
      },
      ".MuiCardContent-root": {
        backgroundColor: "#000000",
        borderRadius: "0px !important",
        padding: "10px !important",
      },
    };

    if (isSelected) {
      style = { ...style, ...selectedStyle };
    } else if (photoFileInfo.escalated || photoFileInfo.adminEscalated) {
      // escalation border is applicable only when the photo is NOT selected
      style = { ...style, ...escalationStyle };
    }
    if (photoFileInfo.adminHide || photoFileInfo.clientHide) {
      style = { ...style, ...hideStyle };
    }
    return style;
  };

  const getTimeString = (): string => {
    // Time in nano seconds
    return (
      photoFileInfo.capturedAt &&
      photoFileInfo.capturedAt.formatUTCNanoSecondsToString(
        "MM/DD/YYYY - hh:mm A",
        true
      )
    );
  };

  const getTags = () => {
    const maxVisibleTags = 3;
    return (
      <>
        {photoTagsList
          .slice(0, maxVisibleTags)
          .map((tag: string, index: number) => (
            <CustomBadge
              key={index}
              content={tag}
              sx={{ mr: "4px", ".MuiBadge-badge": { pt: "12px", pb: "12px" } }}
            />
          ))}
        {photoTagsList.length > maxVisibleTags && (
          <Tooltip
            title={photoTagsList
              .slice(maxVisibleTags)
              .map((tag: string, index: number) => {
                return (
                  <span key={index}>
                    <b key={tag}>
                      {tag}
                      <br />
                    </b>
                  </span>
                );
              })}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: { ...tooltipStyles },
              },
            }}
            disableHoverListener={false}
          >
            <span>
              <MDTypography
                sx={{
                  pl: 0.8,
                  fontSize: "14px",
                  fontWeight: "regular",
                  color: "#FFFFFF",
                }}
              >
                +{photoTagsList.length - maxVisibleTags} more
              </MDTypography>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <MDBox position="relative">
      {photoFileInfo ? (
        <Card sx={getCardStyle()} onClick={handleMediaClick}>
          {enableSelection && (
                  <MDBox
                    className="noBlur"
                    sx={{
                      position: "absolute",
                      top: "15px",
                      right: "0px",
                      zIndex: "301",
                      ".MuiCheckbox-root:not(.Mui-checked)": {
                        ".MuiSvgIcon-root": {
                          background: "#ffffff",
                        },
                      },
                    }}
                  >
                    <CustomCheckbox
                      label={""}
                      checked={isSelected}
                      handleCheckboxChange={() => {}}
                    />
                  </MDBox>
        )}
          <MDBox className="blurContent">
            <MDBox position="relative">
              <MDBox
                display="flex"
                position="absolute"
                top="0"
                width="100%"
                alignItems="center"
                height="70px"
                bgColor="rgba(0, 0, 0, 0.5)"
                zIndex={300}
                pl={1}
              >
                {getTags()}
                
              </MDBox>
              {children}
            </MDBox>
            <CardContent sx={{ padding: "13px 10px 12px 15px" }}>
              <Grid container py={1} sx={photoSectionCardStyle}>
                <Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox sx={{ lineHeight: "18px", marginBottom: "5px" }}>
                      {/* <Link to={"#"} color="#fff">
                      <span style={{textDecoration: "undeline"}}>Test Link</span>
                    </Link> */}
                      {getTypography(
                        "Dollar General 15096 Landscape Vue LA VERGNE TN 263"
                      )}
                    </MDBox>
                    <MDTypography
                      sx={{ ...cardContentStyle, fontSize: "12px" }}
                      variant="caption"
                    >
                      {photoFileInfo.address}
                    </MDTypography>
                    <MDTypography
                      sx={{ ...cardContentStyle, fontSize: "12px" }}
                      variant="caption"
                    >
                      {getTimeString()}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </CardContent>
          </MDBox>
        </Card>
      ) : (
        <CircularProgress color="success" sx={loaderStyle} />
      )}
    </MDBox>
  );
};

export default MediaContainer;
