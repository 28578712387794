import TextField from "@mui/material/TextField";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { searchBarStyle } from "../vues/styles/VueStyles";
import { Grid, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Moment } from "moment";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import EscalatedVueItem from "../dashboard-home/components/EscalatedVueItem";
import {
  GENERIC_ERROR_MESSAGE,
  TEXTFIELD_CHANGE_DELAY,
} from "../../../../constants";
import { useLocation } from "react-router-dom";
import {
  generatePresignedFileURL,
  getClientVues,
} from "../vues/services/VueServices";
import { DashboardVue } from "../dashboard-home/utils/dashboard_interfaces";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { filterDateToNanoSecondsString } from "../vues/vue-grid/helpers/helper_methods";
import { PlaceholderComponent } from "../dashboard-home/components/PlaceholderComponent";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  WebServiceStatus,
  getFileNameInUTC,
  downloadFileToDevice,
  exportVue,
} from "@ivueit/vue-engine";
import { CustomDatePicker } from "@ivueit/vue-engine";

export const SiteDetails = () => {
  const location = useLocation();
  /// Location state variables
  const jobSiteID = location.state?.jobSiteID ?? "";
  const siteName = location.state?.siteName ?? "";
  /// Holds the list of completed Vues
  const [completedVueList, setCompletedVuesList] = useState<DashboardVue[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  /// This is to avoid the unwanted useEffect invocation unless if the search text hasn't chnaged
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [shouldResetDate, setShouldResetDate] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [completedAtStartDate, setCompletedAtStartDate] =
    useState<Moment | null>(null);
  const [completedAtEndDate, setCompletedAtEndDate] = useState<Moment | null>(
    null
  );
  /// State to handle error messages
  const [error, setError] = useState({
    message: "",
    startDate: "",
    endDate: "",
  });

  const getParameterString = () => {
    // Filters based on jobsite ID and statuses
    var parameterString = `jobSiteID=${jobSiteID}&statuses=4`;
    /// Appending Vue Name
    if (searchText.isNotEmpty()) {
      parameterString += `&vueTitle=${searchText}`;
    }
    /// Appending completedAtStartDate
    if (completedAtStartDate?.isValid() && completedAtEndDate?.isValid()) {
      const startDate = filterDateToNanoSecondsString(completedAtStartDate);
      const endDate = filterDateToNanoSecondsString(completedAtEndDate, true);
      parameterString += `&completedAtStartDate=${startDate}&completedAtEndDate=${endDate}`;
    }
    return parameterString;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Check for the valid start date and end date
  const hasValidDateRange = () => {
    return (
      (completedAtStartDate?.isValid() && completedAtEndDate?.isValid()) ||
      (!completedAtStartDate?.isValid() && !completedAtEndDate?.isValid())
    );
  };

  const fetchVueListFromServer = async () => {
    setShowLoader(true);
    if (jobSiteID && hasValidDateRange()) {
      const parameters = getParameterString();
      const response = await getClientVues(parameters);
      if (response.status === WebServiceStatus.success) {
        const {
          submissions,
        }: {
          submissions: DashboardVue[];
        } = response.data;
        /// Condition where we have no completed Vues list available
        if (!submissions.length) {
          setError((prev) => ({
            ...prev,
            message: "There are no completed Vues",
          }));
          setCompletedVuesList([]);
          setShowLoader(false);
          return;
        }
        setCompletedVuesList(submissions);
        setError((prev) => ({
          ...prev,
          message: "",
        }));
      } else {
        setError((prev) => ({
          ...prev,
          message: GENERIC_ERROR_MESSAGE,
        }));
      }
    } else if (!location.state.jobSiteID) {
      setError((prev) => ({
        ...prev,
        message: GENERIC_ERROR_MESSAGE,
      }));
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    // Fetching the vue list on page load
    fetchVueListFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetching the vue list on date change
    if (dateChanged) {
      fetchVueListFromServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAtStartDate, completedAtEndDate]);

  /// Search field changes
  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performSearchBySiteName = (searchText: string) => {
        setSearchText(searchText);
        fetchVueListFromServer();
      };

      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performSearchBySiteName(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  /// Parameters for Vue export
  const getParametersForVueExport = () => {
    var params: any = {};
    params = {
      ...params,
      vueIds: [],
      statuses: [4],
      jobSiteId: jobSiteID,
    };
    // Appending vueTitle value
    if (searchText.isNotEmpty()) {
      params = { ...params, vueTitle: searchText };
    }
    // Appending completedAtStartDate
    if (completedAtStartDate?.isValid()) {
      const startDate = filterDateToNanoSecondsString(completedAtStartDate);
      params = {
        ...params,
        completedAtStartDate: startDate,
      };
    }
    // Appending completedAtEndDate
    if (completedAtEndDate?.isValid()) {
      const endDate = filterDateToNanoSecondsString(completedAtEndDate, true);
      params = {
        ...params,
        completedAtEndDate: endDate,
      };
    }
    return params;
  };

  // Export Vue
  const handleExport = async () => {
    const params = getParametersForVueExport();
    setShowLoader(true);
    const response = await exportVue(params);
    if (response.status === WebServiceStatus.success) {
      const fileID = response.data.fileId;
      if (fileID) {
        const response = await generatePresignedFileURL(fileID);
        if (response.status === WebServiceStatus.success) {
          const presignedUrl = response.data.url;
          const fileName = `export_Vue_Export_Excel_${getFileNameInUTC()}_UTC.csv`;
          try {
            await downloadFileToDevice(presignedUrl, fileName, "text/csv");
          } catch (error) {
            setSnackbarContent({
              title: "Attention!",
              message: `Failed to download ${fileName}`,
              isError: true,
            });
          }
          setSnackbarContent({
            title: "Exported Vue!",
            message: "Completed Vues exported successfully.",
            isError: false,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No file found.",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getTextField = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search by Vue Name"
        sx={{ ...searchBarStyle, maxWidth: "300px", pt: "25px" }}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => {
          setSearchTextChanged(true);
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  /// Handles the onChange of startDate
  const handleStartDateChange = async (newDate: Moment | null) => {
    setShouldResetDate(false);
    setDateChanged(true);
    setError((prev) => ({
      ...prev,
      endDate: "",
      startDate:
        newDate && completedAtEndDate
          ? newDate > completedAtEndDate || !newDate.isValid()
            ? "Invalid start date."
            : ""
          : "",
    }));
    if (!error.startDate && !error.endDate) {
      setCompletedAtStartDate(newDate);
    }
  };

  /// Handles the onChange of endDate
  const handleEndDateChange = async (newDate: Moment | null) => {
    setShouldResetDate(false);
    setDateChanged(true);
    setError((prev) => ({
      ...prev,
      startDate: "",
      endDate:
        newDate && completedAtStartDate
          ? completedAtStartDate > newDate || !newDate.isValid()
            ? "Invalid end date."
            : ""
          : "",
    }));
    if (!error.startDate && !error.endDate) {
      setCompletedAtEndDate(newDate);
    }
  };

  const getDatePickers = () => {
    return (
      <MDBox
        ml={3}
        display="flex"
        sx={{
          ".MuiFormHelperText-root": {
            position: "absolute",
            top: "calc( 100% - 3px )",
            left: "2px",
          },
        }}
      >
        <MDBox>
          <CustomDatePicker
            title="Start Date"
            errorMessage={error.startDate}
            defaultDate={completedAtStartDate}
            onChange={handleStartDateChange}
            maximumDate={completedAtEndDate}
            shouldResetDate={shouldResetDate}
          />
        </MDBox>
        <MDBox ml={1.8}>
          <CustomDatePicker
            title="End Date"
            errorMessage={error.endDate}
            defaultDate={completedAtEndDate}
            minimumDate={completedAtStartDate}
            onChange={handleEndDateChange}
            shouldResetDate={shouldResetDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  const getTotalVuesCountAndExportButton = () => {
    return (
      <MDBox ml="auto" display="flex" paddingTop="20px">
        <MDBox display="flex" alignItems="end" flexDirection="column" mr={4}>
          <MDTypography
            fontSize="14px"
            fontWeight="regular"
            mb={0.8}
            sx={{ lineHeight: "18px", color: "#6E7491" }}
          >
            Total Vues
          </MDTypography>
          <MDTypography
            fontSize="20px"
            fontWeight="bold"
            sx={{ lineHeight: "20px" }}
          >
            {completedVueList.length}
          </MDTypography>
        </MDBox>
        <MDButton
          disabled={!completedVueList.length}
          variant="contained"
          color="info"
          onClick={handleExport}
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            margin: "auto",
            px: "12px",
          }}
        >
          EXPORT
        </MDButton>
      </MDBox>
    );
  };

  return (
    <DashboardContentLayout needCardBackground={false}>
      <MDBox pl="10px">
        {showLoader && <CustomIndicator />}
        <MDBox display="flex" mt={1}>
          {getTextField()}
          {getDatePickers()}
          {getTotalVuesCountAndExportButton()}
        </MDBox>
        <MDBox mt={1.2} mb={3.2}>
          <MDTypography fontSize="20px" fontWeight="bold" mt="10px">
            Site History
          </MDTypography>
          <MDTypography sx={{ lineHeight: "20px" }}>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              Completed Vues For Site:{" "}
            </span>
            <span style={{ fontSize: "16px", fontWeight: "regular" }}>
              {siteName}
            </span>
          </MDTypography>
        </MDBox>
        <MDBox mt={3}>
          {!error.message ? (
            <Grid container spacing={3}>
              {completedVueList.map((vueItem, index) => {
                return (
                  <EscalatedVueItem
                    key={index}
                    dashboardVue={vueItem}
                    shouldDownloadMapFromId={true}
                  />
                );
              })}
            </Grid>
          ) : (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="50vh"
              minHeight="200px"
              flexGrow="1 !important"
            >
              <PlaceholderComponent label={error.message} />
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};
