import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import logo5 from "assets/images/logo7.png";
import MDBox from "components/MDBox";
import { tooltipStyles } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { Tooltip } from "@mui/material";

export const SurveyScoreGridHeader = () => {
  return (
    <MDBox display="flex" alignItems="center" mr="8px">
      <MDBox display="flex" alignItems="center" height="20px" lineHeight="20px">
        <Tooltip
          title="This score is based on a weighting scale assigned to specific questions in your survey."
          arrow
          placement="left"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: "12px",
                      "&.MuiTooltip-tooltip": {
                        backgroundColor: "#344767 !important",
                        padding: "8px",
                        borderRadius: "2px",
                        lineHeight: "14px",
                        maxWidth: "300px",
                        textAlign: "left"
                      },
                      ".MuiTooltip-arrow": {
                        color: "#344767 !important"
                      }
              },
            },
          }}
        >
          <span>
            <Icon
              path={mdiInformation}
              size={0.8}
            />
          </span>
        </Tooltip>
        <MDBox ml="8px">
          <img src={logo5} alt="ivue" height="20px" width="auto" />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
