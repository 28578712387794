import { searchBarStyle } from "../../vues/styles/VueStyles";
import { TextField, InputAdornment, Tooltip, Grid } from "@mui/material";
import { Search } from "@mui/icons-material";
import { CustomDatePicker, CustomDialogBox, CustomDropdown, CustomDropdownOption } from "@ivueit/vue-engine";
import { useCallback, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Moment } from "moment";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "../../site-lists/components/SiteListActionBar";
import {
  mdiEye,
  mdiEyeOff,
  mdiImageMove,
  mdiTag,
  mdiTrayArrowDown,
} from "@mdi/js";
import { tooltipStyles } from "../../site-lists/styles/site_list_style";
import { PhotoFileInfo } from "../../vues/vue_detail/utils/vue_detail_interface";
import PhotoThumbnail from "./PhotoThumbnail";
import VideoThumbnail from "./VideoThumbnail";
import { TagMediaDialogContent } from "./TagMediaDialogContent";
import { AddToAlbumDialogContent } from "./AddToAlbumDialogContent";
import { useNavigate } from "react-router";
import { DEFAULT_PAGE_SIZE, RoutePath } from "../../../../../constants";
import { MediaContainer } from "./MediaContainer";
import { TEXTFIELD_CHANGE_DELAY } from "./../../../../../constants";
import {
  MAXIMUM_DOWNLOAD_ALLOWED,
  NO_MEDIA_SELECTED,
  availableMediaTypes,
  defaultMediaType,
  defaultMetaData,
  getMediaType,
} from "../utils/constants";
import { filterDateToNanoSecondsString } from "../../vues/vue-grid/helpers/helper_methods";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { getClientMediaInfo } from "../services/MediaHubServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { ClientMediaAPIMetaData } from "../utils/interfaces";

const MediaHubGallery = ({
  containerRef,
  isGalleryScreen,
}: {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  isGalleryScreen: boolean;
}) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [selectedMediaType, setSelectedMediaType] =
    useState<string>(defaultMediaType);
  const [searchText, setSearchText] = useState("");
  const [searchTextChanged, setSearchTextChanged] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  /// To handle error
  const [dateError, setDateError] = useState({
    startDate: "",
    endDate: "",
  });
  const [metaValues, setMetaValues] =
    useState<ClientMediaAPIMetaData>(defaultMetaData);
  // Decides whether the grid items can be selected or not
  const [enableSelection, setEnableSelection] = useState(false);
  // TODO: Remove dummy values & convert to object when API is ready
  const [availableMediaItems, setAvailableMediaItems] = useState<
    PhotoFileInfo[]
  >([]);
  // Contains the media items selected by the user
  const [selectedMediaItems, setSelectedMediaItems] = useState<PhotoFileInfo[]>(
    []
  );
  /// States to handle the dialogs
  const [showTagMediaDialog, setShowTagMediaDialog] = useState<boolean>(false);
  const [showAddToAlbumDialog, setShowAddToAlbumDialog] =
    useState<boolean>(false);

  const handleDropDownClick = (newValue: string) => {
    setSelectedMediaType(newValue);
  };

  const getSearchTextfield = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search"
        sx={searchBarStyle}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => {
          setSearchTextChanged(true);
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  /// Handles the onChange of startDate and endDate
  const handleStartDateChange = (newDate: Moment | null) => {
    setDateError((prev) => ({
      startDate: newDate
        ? newDate > endDate || !newDate.isValid()
          ? "Invalid start date."
          : ""
        : "",
      endDate: "",
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setStartDate(date);
  };

  const handleEndDateChange = (newDate: Moment | null) => {
    setDateError((prev) => ({
      startDate: "",
      endDate: newDate
        ? startDate > newDate || !newDate.isValid()
          ? "Invalid end date."
          : ""
        : "",
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    setEndDate(date);
  };

  const getDatePickers = () => {
    const helperTextStyle = {
      "p.MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-16px",
        left: "5px",
      },
    };
    return (
      <MDBox ml={3} display="flex">
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="Start Date"
            defaultDate={null}
            minimumDate={startDate}
            onChange={handleStartDateChange}
            errorMessage={dateError.startDate}
          />
        </MDBox>
        <MDBox ml={1} position="relative" sx={helperTextStyle}>
          <CustomDatePicker
            title="End Date"
            defaultDate={null}
            minimumDate={endDate}
            onChange={handleEndDateChange}
            errorMessage={dateError.endDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  const getLeftTopbarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1">
        {getSearchTextfield()}
        <CustomDropdown
          title={"Media Type"}
          selectedItem={{
            value: selectedMediaType,
            displayTitle: selectedMediaType,
          }}
          availableValues={availableMediaTypes.map((type) => {
            return {
              value: type,
              displayTitle: type,
            };
          })}
          onChange={(newValue: CustomDropdownOption) => {
            handleDropDownClick(newValue.value);
          }}
          prefixTitle={true}
          minWidth="175px"
        />

        {getDatePickers()}
      </MDBox>
    );
  };

  const getRightTopBarItems = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1" pl="10px">
        <MDBox mr={2.8} display="flex" flexDirection="column" alignItems="end">
          <MDTypography
            variant="button"
            color="textLight"
            fontWeight="regular"
            sx={{ lineHeight: "16px", mb: "4px" }}
          >
            {enableSelection ? "Media Selected" : "Media Total"}
          </MDTypography>
          <MDTypography variant="h5">
            {enableSelection
              ? `${selectedMediaItems.length}/${availableMediaItems.length}`
              : `${availableMediaItems.length}`}
          </MDTypography>
        </MDBox>
        {enableSelection ? (
          <MDButton
            variant="outlined"
            color="primary"
            sx={{
              height: "36px",
              borderColor: "#c7ccd0",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={() => {
              setEnableSelection(false);
              setSelectedMediaItems([]);
            }}
          >
            CANCEL
          </MDButton>
        ) : (
          <MDButton
            variant="contained"
            color="info"
            sx={{
              fontSize: "14px",
            }}
            onClick={() => {
              setEnableSelection(true);
            }}
            disabled={false}
          >
            SELECT
          </MDButton>
        )}
      </MDBox>
    );
  };

  const getHideMediaButton = () => {
    // Decides whether to disable the button or not
    const disableActionBar = selectedMediaItems.length <= 0;
    // Whether having hidden items
    const hasHiddenItems = selectedMediaItems.some((media) => media.clientHide);
    // Checks whether having visible items
    const hasVisibleItems = selectedMediaItems.some(
      (media) => !media.clientHide
    );
    let disableHideButton = false;
    let tooltipMessage = "";
    let icon = mdiEyeOff;
    let title = "Hide";
    // Handling the button enabili
    if (disableActionBar) {
      disableHideButton = true;
      tooltipMessage = NO_MEDIA_SELECTED;
    } else if (hasHiddenItems && hasVisibleItems) {
      disableHideButton = true;
      // Selected items contains both hidden items and visible items
      tooltipMessage = "Please make sure all media items have the same status.";
    } else if (hasHiddenItems) {
      icon = mdiEye;
      title = "Unhide";
    }

    return (
      <Tooltip
        title={tooltipMessage}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableHideButton}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={icon}
            disabled={disableHideButton}
            onClick={() => {
              if (hasHiddenItems) {
                // TODO: show medias
              } else {
                // TODO: hide medias
              }
            }}
          >
            {title}
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getDownloadButton = () => {
    // Decides whether to disable the button or not
    const disableActionBar = selectedMediaItems.length <= 0;
    const didSelectMaximum =
      selectedMediaItems.length > MAXIMUM_DOWNLOAD_ALLOWED;

    const disableDownloadButton = disableActionBar || didSelectMaximum;
    let tooltipMessage = "";
    if (disableActionBar) {
      tooltipMessage = NO_MEDIA_SELECTED;
    } else if (didSelectMaximum) {
      tooltipMessage = `This action is unavailable because more than ${MAXIMUM_DOWNLOAD_ALLOWED} selections have been made.`;
    }
    return (
      <Tooltip
        title={tooltipMessage}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableDownloadButton}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiTrayArrowDown}
            disabled={disableDownloadButton}
            onClick={() => {}}
          >
            Download
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getTagButton = () => {
    // Disables the action bar when no media items are selected
    const disableActionBar = selectedMediaItems.length <= 0;
    return (
      <Tooltip
        title={NO_MEDIA_SELECTED}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableActionBar}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiTag}
            onClick={() => {
              setShowTagMediaDialog(true);
            }}
            disabled={disableActionBar}
          >
            Tag
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getAddToAlbumButton = () => {
    // Disables the action bar when no media items are selected
    const disableActionBar = selectedMediaItems.length <= 0;

    return (
      <Tooltip
        title={NO_MEDIA_SELECTED}
        arrow
        placement="right"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableActionBar}
      >
        <span>
          <StyledButtonWithIcon
            iconPath={mdiImageMove}
            onClick={() => {
              setShowAddToAlbumDialog(true);
            }}
            disabled={disableActionBar}
          >
            Add to Album
          </StyledButtonWithIcon>
        </span>
      </Tooltip>
    );
  };

  const getRemoveFromAlbumButton = () => {
    const disableActionBar = selectedMediaItems.length <= 0;

    return (
      !isGalleryScreen && (
        <MDBox mr={1.6} mt="25px">
          <Tooltip
            title={NO_MEDIA_SELECTED}
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!disableActionBar}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiImageMove}
                onClick={() => {
                  /// TODO: Functionality
                }}
                disabled={disableActionBar}
                color="error"
              >
                Remove from Album
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </MDBox>
      )
    );
  };

  const getActionBar = () => {
    return (
      <MDBox display="flex" alignItems="end" flexGrow="1">
        <MDBox mr={1.6} mt="25px">
          {getTagButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getHideMediaButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getDownloadButton()}
        </MDBox>
        <MDBox mr={1.6} mt="25px">
          {getAddToAlbumButton()}
        </MDBox>
        {getRemoveFromAlbumButton()}
      </MDBox>
    );
  };

  const getNoMediaLabel = () => {
    return (
      <MDBox display="flex" justifyContent="center" mt="15vh">
        <MDTypography>No media available</MDTypography>
      </MDBox>
    );
  };

  const getMediaItems = () => {
    return (
      <MDBox>
        <MDBox mb={1.8} mt={1.8}>
          <MDTypography variant="h5">January 2024</MDTypography>
        </MDBox>
        <Grid container spacing={2}>
          {availableMediaItems.map((media) => {
            const selectedIndex = selectedMediaItems.findIndex(
              (photo: PhotoFileInfo) => photo.id === media.id
            );
            const isSelected = selectedIndex !== -1;
            return (
              <Grid item xs={6} md={4}>
                <MediaContainer
                  photoFileInfo={media}
                  enableSelection={enableSelection}
                  handleMediaClick={(e) => {
                    e.stopPropagation();
                    if (enableSelection) {
                      // e.shiftKey decides whether the shift key is pressed or not
                      handleMediaSelection(selectedIndex, media, e.shiftKey);
                    } else {
                      navigate(`${RoutePath.vues}/${media.canonicalId}`);
                    }
                  }}
                  isSelected={isSelected}
                >
                  {media.mimeType === "video/mp4" ? (
                    <VideoThumbnail photoFileInfo={media} />
                  ) : (
                    <PhotoThumbnail photoFileInfo={media} />
                  )}
                </MediaContainer>
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    );
  };

  // Handles media selection
  const handleMediaSelection = (
    indexOfSelectedMedia: number, // Index of item in the selected item array
    selectedMedia: PhotoFileInfo,
    shiftPressed: boolean
  ) => {
    // Handles multi selection with shiftKey
    if (shiftPressed) {
      handleMultiSelectionWithShiftKey(selectedMedia);
    } else {
      // Handles selection with click
      let selectedItems = [...selectedMediaItems];
      if (indexOfSelectedMedia !== -1) {
        // Deselecting the item
        selectedItems.splice(indexOfSelectedMedia, 1);
      } else {
        // Selecting a new item
        selectedItems.push(selectedMedia);
      }
      setSelectedMediaItems(selectedItems);
    }
  };

  // Handles multi selection with shiftKey
  const handleMultiSelectionWithShiftKey = (selectedMedia: PhotoFileInfo) => {
    const indexOfCurrentItem = availableMediaItems.findIndex(
      (mediaItem) => mediaItem.id === selectedMedia.id
    );

    let lastSelectedItemIndex = indexOfCurrentItem;
    const totalSelectedItems = selectedMediaItems.length;
    let newlySelectedItems: PhotoFileInfo[] = [];
    if (totalSelectedItems > 0) {
      const idOfLastItem = selectedMediaItems[totalSelectedItems - 1].id;
      lastSelectedItemIndex = availableMediaItems.findIndex(
        (mediaItem) => mediaItem.id === idOfLastItem
      );
      let startIndex = lastSelectedItemIndex;
      let endIndex = indexOfCurrentItem;
      // Alows to select in reverse mode
      if (indexOfCurrentItem < lastSelectedItemIndex) {
        startIndex = indexOfCurrentItem;
        endIndex = lastSelectedItemIndex;
      }
      // Finding new items, and excluding already selected items
      const newItems = availableMediaItems
        .slice(startIndex, endIndex + 1)
        // Excluding already selected items
        .filter(
          (media) =>
            !selectedMediaItems.some((selected) => selected.id === media.id)
        );
      newlySelectedItems = [...selectedMediaItems, ...newItems];
    } else {
      // No items selected, so selecting the current item
      newlySelectedItems = [selectedMedia];
    }
    setSelectedMediaItems(newlySelectedItems);
  };

  /// Tag Media dialog
  const getTagMediaDialog = () => {
    return (
      <CustomDialogBox
        title="Tag Media Selections"
        width="440px"
        openDialog={showTagMediaDialog}
      >
        <TagMediaDialogContent
          previouslyAddedTags={[]}
          cancelButtonClick={() => {
            setShowTagMediaDialog(false);
          }}
          saveButtonClick={(selectedTagsList: string[]) => {
            setShowTagMediaDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getAddToAlbumDialog = () => {
    return (
      <CustomDialogBox
        title="Move Media Selections"
        width="302px"
        openDialog={showAddToAlbumDialog}
      >
        <AddToAlbumDialogContent
          handleCancelClick={() => {
            setShowAddToAlbumDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performMediaSearch = (searchText: string) => {
        setSearchText(searchText);
        fetchClientMediaInfoFromServer(false);
      };
      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performMediaSearch(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // Check for the valid start date and end date
  const isValidParameter = () => {
    const isValid =
      (startDate?.isValid() && endDate?.isValid()) ||
      (!startDate?.isValid() && !endDate?.isValid());
    return isValid;
  };

  const getParameterString = (isScrolling: boolean) => {
    // Default page number will be 1 if not scrolling
    var newPageNumber = isScrolling ? metaValues.pageNumber + 1 : 1;
    var pageSize = DEFAULT_PAGE_SIZE;
    var parameterString = `pageSize=${pageSize}&pageNumber=${newPageNumber}`;
    /// Appending Site Name
    if (searchText.isNotEmpty()) {
      parameterString += `&nameQueryString=${searchText}`;
    }
    /// Appending the media type
    if (selectedMediaType.isNotEmpty()) {
      parameterString += `&mediaType=${getMediaType(selectedMediaType)}`;
    }
    /// Appending created start & end dates
    if (startDate !== null && endDate !== null) {
      const formattedStartDate = filterDateToNanoSecondsString(startDate);
      const formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      parameterString += `&createdAtStartTime=${formattedStartDate}&createdAtEndTime=${formattedEndDate}`;
    }
    return parameterString;
  };

  const fetchClientMediaInfoFromServer = useCallback(
    async (isScrolling: boolean) => {
      if (isValidParameter()) {
        setShowLoader(true);
        const parameters = getParameterString(isScrolling);
        const response = await getClientMediaInfo(parameters);
        var mediaItems: PhotoFileInfo[] = [];
        if (response.status === WebServiceStatus.success) {
          /// TODO : Update this once the API is deployed
          /// Also, need to set MetaValues
        } else {
          setAvailableMediaItems([]);
        }
        if (isScrolling) {
          setAvailableMediaItems((prev) => prev.concat(mediaItems));
        } else {
          setAvailableMediaItems(mediaItems);
        }
        setShowLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleOnRowsScrollEnd = async () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const hasReachedEnd = scrollTop + clientHeight >= scrollHeight;
        const shouldScroll =
          setAvailableMediaItems.length < metaValues.totalElements &&
          metaValues.pageNumber < metaValues.totalPages;
        if (shouldScroll && hasReachedEnd) {
          await fetchClientMediaInfoFromServer(true);
        }
      };
      container.addEventListener("scroll", handleOnRowsScrollEnd);
      return () => {
        container.removeEventListener("scroll", handleOnRowsScrollEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchClientMediaInfoFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaType, startDate, endDate]);

  return (
    <MDBox>
      {showTagMediaDialog && getTagMediaDialog()}
      {showAddToAlbumDialog && getAddToAlbumDialog()}
      {showLoader && <CustomIndicator />}
      {/* Top bar items */}
      <MDBox display="flex" alignItems="start" pb={1.4}>
        <MDBox display="flex" flexGrow={1}>
          <MDBox>
            {enableSelection ? getActionBar() : getLeftTopbarItems()}
          </MDBox>
          <MDBox display="flex" ml="auto" mt="10px">
            {getRightTopBarItems()}
          </MDBox>
        </MDBox>
      </MDBox>
      {/* Gallery Content */}
      {availableMediaItems.length <= 0 ? getNoMediaLabel() : getMediaItems()}
    </MDBox>
  );
};

export default MediaHubGallery;
