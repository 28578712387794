import "./index.css";
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  useEffect,
  createContext,
  useState,
} from "react";
// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import SignIn from "pages/authentication/sign-in/SignIn";
import SetupAccount from "pages/authentication/setup-account/SetupAccount";
import ResetPassword from "pages/authentication/reset-password/ResetPassword";
import SMSVerification from "pages/authentication/sms-verification/SMSVerification";
import CheckYourInbox from "pages/authentication/check-your-inbox/CheckYourInbox";
import ResetPasswordCompletion from "pages/authentication/change-password/ChangePassword";
import { RoutePath } from "./constants";
import Sidenav from "layouts/Sidenav";
import { setMiniSidenav, useMaterialUIController } from "context";
import logo_normal from "assets/images/logo4.png";
import logo_mini from "assets/images/logo6.png";
import { ProtectedRoute } from "utils/ProtectedRoute/ProtectedRoute";
import SessionManager from "utils/auto-logout/SessionManager";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TwoFactorAuthentication from "pages/authentication/two-factor/TwoFactorAuthentication";
import { useAuth } from "context/AuthProvider";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { getRoutesList } from "routes";
import VueDetails from "pages/dashboard/home/vues/vue_detail/VueDetails";
import { VueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { downloadFile } from "pages/profile/services/ProfileServices";
import MDAvatar from "components/MDAvatar";
import userPlaceholder from "assets/images/user_filler.svg";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import { hasMinimumRequiredRole } from "utils/helpers/common";
import { SiteDetails } from "pages/dashboard/home/site-details/SiteDetails";
import { AlbumDetailScreen } from "pages/dashboard/home/media-hub/components/AlbumDetailScreen";
import { NotificationDetailScreen } from "pages/notifications/components/NotificationDetailScreen";
import InvoicePreview from "pages/my-account/invoices/components/InvoicePreview";
import ReferralWidget from "utils/referral-widget/referral-widget";
import Invoices from "pages/my-account/invoices/Invoices";

interface TabContextType {
  tabName: string;
  setTabName: React.Dispatch<React.SetStateAction<string>>;
}

export const TabContext = createContext<TabContextType | undefined>(undefined);

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, layout } = controller;
  const { pathname } = useLocation();
  const [fullName, setFullName] = useState<string>("");
  const [tabName, setTabName] = useState<string>("");
  const {
    userData,
    userRole,
  }: {
    userData: UserInfo;
    userRole: UserRoles;
  } = useAuth();
  const [profileUrl, setProfileUrl] = useState<string>("");
  // Whether to display the mini bar or not
  const showMiniNavBar = window.localStorage.getItem("MINI_SIDENAV") === "true";

  useEffect(() => {
    setMiniSidenav(dispatch, showMiniNavBar);
  }, [dispatch, showMiniNavBar]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    /// Downloading profile picture
    const downloadProfilePic = async () => {
      const hasImageId = userData && userData.profileImgId;
      if (hasImageId) {
        const imageData = await downloadFile(userData.profileImgId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileUrl(url);
        }
      } else {
        setProfileUrl(null);
      }
    };
    /// Reflects if there is any change in the userData stored locally
    const handleStorage = () => {
      const user = {
        firstName: userData?.firstName ?? "",
        lastName: userData?.lastName ?? "",
      };
      const fullName = user.firstName + " " + user.lastName;
      setFullName(fullName);
    };

    if (userData) {
      downloadProfilePic();
      handleStorage();
    }
  }, [userData]);

  /// Generates the profile picture element as per the availability of the imageID
  const getProfilePic = () => {
    return userData && userData.profileImgId ? (
      <MDAvatar
        src={profileUrl ?? userPlaceholder}
        alt="Profile Image"
        size="md"
        bgColor="secondary"
        shadow="sm"
      />
    ) : (
      <MDAvatar
        src={userPlaceholder}
        alt="Profile Image"
        size="md"
        bgColor="secondary"
        shadow="sm"
      />
    );
  };

  const generateRoutes = (allRoutes: any[]): any => {
    const filteredRoutes = getRoutesAfterRoleCheck(allRoutes);
    return filteredRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        // Returning routes when the
        if (route.collapse && route.route) {
          return [
            <Route
              path={route.route}
              element={<ProtectedRoute>{route.component}</ProtectedRoute>}
              key={route.key}
            />,
            ...generateRoutes(route.collapse),
          ];
        }

        if (route.collapse) {
          return generateRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              path={route.route}
              element={<ProtectedRoute>{route.component}</ProtectedRoute>}
              key={route.key}
            />
          );
        }

        return null;
      }
    );
  };
  // Filters based on required role
  const getRoutesAfterRoleCheck = (list: any[]) => {
    const allRoutes = list.filter((route) => {
      const requiredRole = route.requiredRole ?? UserRoles.user;
      return hasMinimumRequiredRole({ userRole, requiredRole });
    });
    const finalRoutes = allRoutes.map((route) => {
      var updatedRoute = { ...route };
      // Handling inner routes to verify the roles
      if (route.collapse) {
        const updatedCollapse = (route.collapse as any[]).filter((collapse) => {
          const requiredRole = collapse.requiredRole ?? UserRoles.user;
          return hasMinimumRequiredRole({ userRole, requiredRole });
        });
        updatedRoute.collapse = updatedCollapse;
      }
      return updatedRoute;
    });
    return finalRoutes;
  };

  const getProfileRoute = (): any => {
    return getRoutesAfterRoleCheck(getRoutesList(fullName, getProfilePic()));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TabContext.Provider value={{ tabName, setTabName }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <SessionManager>
                <Sidenav
                  color={sidenavColor}
                  brand={miniSidenav ? logo_mini : logo_normal}
                  brandName=""
                  routes={getProfileRoute()}
                />
              </SessionManager>
            </>
          )}
          <Routes>
            {generateRoutes(getProfileRoute())}
            <Route index path={RoutePath.root} element={<SignIn />} />
            <Route path={RoutePath.setupAccount} element={<SetupAccount />} />
            <Route path={RoutePath.resetPassword} element={<ResetPassword />} />
            <Route path={RoutePath.checkInbox} element={<CheckYourInbox />} />
            <Route
              path={RoutePath.smsVerification}
              element={<SMSVerification />}
            />
            <Route
              path={RoutePath.changePassword}
              element={<ResetPasswordCompletion />}
            />
            <Route
              path={RoutePath.authenticateUser}
              element={<TwoFactorAuthentication />}
            />
            <Route path={RoutePath.vueDashboard}>
              <Route
                path=":canonicalId"
                element={
                  <ProtectedRoute>
                    <VueProvider>
                      <VueDetails />
                    </VueProvider>
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={RoutePath.vues}>
              <Route
                path=":canonicalId"
                element={
                  <ProtectedRoute>
                    <VueProvider>
                      <VueDetails />
                    </VueProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="site-details"
                element={
                  <ProtectedRoute>
                    <SiteDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":canonicalId/site-details"
                element={
                  <ProtectedRoute>
                    <SiteDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={RoutePath.siteLists}>
              <Route
                path="site-details"
                element={
                  <ProtectedRoute>
                    <SiteDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={RoutePath.mediahub}>
              <Route path=":albumName" element={<AlbumDetailScreen />} />
              <Route
                path=":canonicalId"
                element={
                  <ProtectedRoute>
                    <VueProvider>
                      <VueDetails />
                    </VueProvider>
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={RoutePath.errorPage}
              element={<Navigate to={RoutePath.root} />}
            />
            <Route
              path={RoutePath.notifications}
              element={
                <ProtectedRoute>
                  <NotificationDetailScreen />
                </ProtectedRoute>
              }
            />
            {/* ADDED TEMPORARILY FOR Manual URL Navigation - START */}
            <Route path={RoutePath.invoices}>
              <Route
                path={RoutePath.invoices}
                element={
                  <ProtectedRoute>
                    <Invoices />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* ADDED TEMPORARILY FOR Manual URL Navigation - END */}
            <Route path={RoutePath.invoices}>
              <Route
                path=":invoiceId"
                element={
                  <ProtectedRoute>
                    <InvoicePreview />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
          {userData && (
            <ReferralWidget
              firstName={fullName}
              email={userData.email}
              pathName={pathname}
            />
          )}
        </ThemeProvider>
      </TabContext.Provider>
    </LocalizationProvider>
  );
}
