import {
  CustomIndicator,
  PlaceholderComponent,
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "@ivueit/vue-engine";
import { InfoOutlined, Search } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  Divider,
  List,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { useEffect, useState } from "react";
import { listStyle } from "../../vues/vue-grid/components/CustomSearchBarWithList";
import { CustomDeletableChip } from "../../vues/vue_detail/components/CustomDeletableChip";
import { VueTag } from "../../vues/vue_detail/components/TagVueDialogContent";
import { alphanumericSort } from "utils/helpers/extensions";

interface Props {
  cancelButtonClick: () => void;
  previouslyAddedTags: string[];
  saveButtonClick: (selectedTagsList: string[]) => void;
}

export const TagMediaDialogContent = (props: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [showLoader, setShowLoader] = useState(false);
  const [addedTagsList, setAddedTagsList] = useState<VueTag[]>([]);
  const [allTagsList, setAllTagsList] = useState<VueTag[]>([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [error, setError] = useState<string>("");

  /// Handles the enability of "SAVE" button
  useEffect(() => {
    const addedTags = addedTagsList.map((tag) => tag.name).sort();
    const hasNewTags =
      JSON.stringify(addedTags) !==
      JSON.stringify(props.previouslyAddedTags.sort());
    setEnableSaveButton(hasNewTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTagsList]);

  useEffect(() => {
    /// TODO: Integrate fetch tags API once it is ready & remove the dummy data
    const ALL_TAGS = [].map((tag, index) => {
      return { id: index.toString(), name: tag, isImmutable: false };
    });
    setAllTagsList(ALL_TAGS);
    const ADDED_TAGS = props.previouslyAddedTags.map((tag, index) => {
      return { id: index.toString(), name: tag, isImmutable: false };
    });
    setAddedTagsList(ADDED_TAGS);
  }, [props.previouslyAddedTags]);

  /// Handles the checkbox change
  const handleCheckboxChange = (selectedTag: VueTag) => {
    setSearchInput("");
    setAddedTagsList([...addedTagsList, selectedTag]);
    setAllTagsList(allTagsList.filter((tag) => tag.id !== selectedTag.id));
  };

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  /// Handles the chip delete
  const handleChipDelete = (selectedItem: VueTag) => {
    var newTagList = [...allTagsList];
    newTagList.push(selectedItem);
    setAllTagsList(newTagList);
    setAddedTagsList(addedTagsList.filter((tag) => tag.id !== selectedItem.id));
  };

  /// Handles the onClick of "Save" button
  const handleSaveButtonClick = async () => {
    /// TODO: Integrate Update tags API once it is ready
    props.saveButtonClick(addedTagsList.map((item) => item.name));
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <MDBox px={2} pt={2}>
        {error && (
          <MessageAlert message={error} type="error" icon={<InfoOutlined />} />
        )}
        <TextField
          fullWidth
          placeholder="Search Tags"
          value={searchInput}
          InputLabelProps={{ shrink: true }}
          onChange={handleSearchInputChange}
          sx={{ py: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="medium" sx={{ color: "#344767" }} />
              </InputAdornment>
            ),
          }}
        />
        <MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }}>
              Added Tags ({addedTagsList.length})
            </MDTypography>
            <Divider
              sx={{
                backgroundColor: "#A8B8D8",
                height: "1x",
                opacity: 0.5,
                flexGrow: "1",
                ml: "6px",
              }}
            />
          </MDBox>
          <MDBox
            sx={{
              maxHeight: "154px",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "6px",
              },
              "::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#9F9FA1",
                borderRadius: "3px",
                ":hover": {
                  background: "#AF9FA1",
                },
              },
            }}
          >
            {addedTagsList.length > 0 ? (
              addedTagsList
                .sort((a, b) => alphanumericSort(a.name, b.name))
                .map((checkedItem) => (
                  <CustomDeletableChip
                    key={checkedItem.id}
                    label={checkedItem.name}
                    onClick={() => {
                      handleChipDelete(checkedItem);
                    }}
                    isImmutable={checkedItem.isImmutable}
                  />
                ))
            ) : (
              <PlaceholderComponent label={"No tags added"} fontSize="18px" />
            )}
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }}>
              All Tags
            </MDTypography>
            <Divider
              sx={{
                backgroundColor: "#A8B8D8",
                opacity: 0.5,
                height: "1px",
                flexGrow: "1",
                ml: "6px",
              }}
            />
          </MDBox>
          <MDBox>
            {allTagsList.length > 0 ? (
              <List sx={listStyle}>
                {allTagsList
                  .sort((a, b) => alphanumericSort(a.name, b.name))
                  .filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(searchInput.trim().toLowerCase())
                  )
                  .map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              handleCheckboxChange(item);
                            }}
                          />
                        }
                        label={item.name}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: "400",
                          },
                        }}
                      />
                    </FormGroup>
                  ))}
              </List>
            ) : (
              <PlaceholderComponent
                label={"No tags available"}
                fontSize="18px"
              />
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        sx={{
          backgroundColor: "#A8B8D8",
          height: "1px",
          opacity: 0.5,
          m: 0,
          backgroundImage: "none !important",
        }}
      />
      <MDBox px={2} py={2} display="flex" justifyContent="flex-end">
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          size="small"
          onClick={props.cancelButtonClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          disabled={!enableSaveButton}
          sx={dialogSaveButtonStyle}
          size="small"
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </MDBox>
    </>
  );
};
